'use strict';

// Constructor
var Header = function() {
  this.name = 'Header';
  console.log('%s module', this.name);


	

	var showMenu = false;

	$('#bm-header-mobile').click(function(){
	    $('#bm-nav-mobile').fadeToggle( 200, "swing" );
	    //$(this).toggleClass('closed');
	});


};

module.exports = Header;
