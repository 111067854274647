// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

var $ = require('jquery');
var bootstrap = require('bootstrap');
//var scrollprogress = require('scrollprogress');
  

var Header = require('../_modules/header/header');

$(function() {

  new Header();
  //scrollProgress.set();
  console.log('Welcome!');

});
/*
window.onload = function() {

	scrollProgress.set({ styles: false });
	window.onresize = scrollProgress.update;
	window.onscroll = scrollProgress.trigger;
};

*/


